<template>
  <div>
    <Environment v-if="environment != 'production'" />
    <cmsHeader />
    <Toast />
    <div class="container" v-if="event">
      <div class="content">
      <div class="title">Events : Add </div>
      Select Active to show your event on the Events or Summer Sessions page or select Pending 
      to temporarily hide the event. Enter the information below and click the Submit button to 
      submit your event.
    </div>
    <div class="form-container">
      <div id="statusId" class="form-container-section">
        <span class="form-container-label">Status * : </span>
        <span class="form-container-input">
          <RadioButton id="status1" name="statusId" v-model="statusId" :value="Number(1)" /> <label for="status1">Active</label>
          <RadioButton id="status2" name="statusId" v-model="statusId" :value="Number(2)" /> <label for="status2">Pending</label>
        </span>
      </div>
      <div class="error-message"><small id="status_help">{{errors.statusId}}</small></div>
      <div id="isSummerSession" class="form-container-section">
        <span class="form-container-label">Summer Session * : </span>
        <span class="form-container-input">
          <RadioButton id="session1" name="isSummerSession" :value="true" v-model="isSummerSession" /> <label for="session1">Yes</label>
          <RadioButton id="session2" name="isSummerSession" :value="false" v-model="isSummerSession" /> <label for="session1">No</label>
        </span>
      </div>
      <div class="error-message"><small id="isSummerSession_help">{{errors.isSummerSession}}</small></div>
      <div id="title" class="form-container-section">
        <span class="form-container-label"> Title * : </span>
        <span class="form-container-input">
          <InputText name="title" v-model="title" size="150" :class="{ 'p-invalid': errors.title }" />
        </span>
      </div>
      <div class="error-message"><small id="title_help">{{errors.title}}</small></div>
      <div id="subTitle" class="form-container-section">
        <span class="form-container-label"> Subtitle * : 
        </span>
        <span class="form-container-input">
          <InputText name="subTitle" v-model="subTitle" size="150" :class="{ 'p-invalid': errors.subTitle }" />
        </span>
      </div>
      <div class="error-message"><small id="subTitle_help">{{errors.subTitle}}</small></div>
      <div id="startDate" class="form-container-section">
        <span class="form-container-label"> Date * : </span>
        <span class="form-container-input">
          <Calendar v-model="date" 
            :showIcon="true"
            :class="{ 'p-invalid': errors.date }"
          />
        <small> &nbsp; Select the first day of training.</small>
        </span>
      </div>
      <div class="error-message"><small id="date_help">{{errors.date}}</small></div>
      <div id="consecutiveDays" class="form-container-section">
        <span class="form-container-label"> Consecutive Days? : </span>
        <span class="form-container-input">
          <Checkbox v-model="isConsecutive" :binary="true" />
        </span>
      </div>
      <div id="location" class="form-container-section">
        <span class="form-container-label"> Location * : </span>
        <span class="form-container-input">
          <InputText name="location" size="50" v-model="location" :class="{ 'p-invalid': errors.location }" />
        </span>
      </div>
      <div class="error-message"><small id="location_help">{{errors.location}}</small></div>
      <div id="select-trainer" class="form-container-section">
        <span class="form-container-label"> Available Trainers * : </span>
        <span class="form-container-input">
          <Dropdown 
            style="width:250px;height:25px;text-align:left;"
            :options="trainers"
            optionLabel="name"
            placeholder="Select a Trainer"
            v-model="trainer"
          >
          <template #value="slotProps">
            <div v-if="slotProps.value">
                <div>{{ slotProps.value.name }}</div>
            </div>
            <span v-else>
                {{slotProps.placeholder}}
            </span>
          </template>

          <template #option="slotProps">
              <div>
                  <div><img class="profile-image" name="trainerimage" :src="baseURL + trainerImagesPath + slotProps.option.imageFilename"> {{slotProps.option.name}}, {{slotProps.option.title}}</div>
              </div>
          </template>
        </Dropdown>&nbsp;
        <Button 
          class="p-button-raised p-button-rounded p-button-lg"
          icon="pi pi-plus"
          @click="addTrainer()"
        />
        </span>
      </div>

      <div id="location" class="form-container-section">
        <span class="form-container-label"> Selected Trainers * : </span>
        <span class="form-container-input message" v-if="selectedTrainers.length <= 0">
          No trainers selected! 
        </span>
        <span class="form-container-input" v-if="selectedTrainers.length >= 1">
          <span v-for="(trainer, index) in selectedTrainers" :key="trainer.id">
            <img class="selected-profile-image" name="trainerimage" :src="baseURL + trainerImagesPath + trainer.imageFilename" @click="removeTrainer(index)">&nbsp;
          </span>
        </span>
      </div>

      <div id="description" class="form-container-section">
        <span class="form-container-label"> Description * : </span>
        <span class="form-container-input">
          <Editor
            v-model="description"
            editorStyle="height: 320px"
            :class="{ 'p-invalid': errors.description }"
          />
        </span>
      </div>
      <div class="error-message"><small id="description_help">{{errors.description}}</small></div>
      <div id="program" class="form-container-section">
        <span class="form-container-label"> Program * : </span>
        <span class="form-container-input">
          <Editor
            v-model="program"
            editorStyle="height: 320px"
            :class="{ 'p-invalid': errors.program }"
          />
        </span>
      </div>
      <div class="error-message"><small id="program_help">{{errors.program}}</small></div>
      <div id="accommodations" class="form-container-section">
        <span class="form-container-label"> Accommodations : </span>
        <span class="form-container-input">
          <Editor
            v-model="accommodation"
            editorStyle="height: 320px"
          />
        </span>
      </div>
      <div id="imageUpload" class="form-container-section" v-show="!imageFilename && !uploadedImageFileName">
        <span class="form-container-label"> Image : </span>
        <span class="form-container-input">
          <FileUpload 
            name="files[]" 
            :url="`${baseURL}/api/v1/upload/event/images`" 
            @error="uploadError" 
            @upload="uploadComplete"
          />
        </span>
      </div>
      <div class="form-group form-group-sm" v-show="uploadedImageFileName || imageFilename">
      <label class="col-xs-12 col-sm-2 control-label" for="formGroupInputSmall">Uploaded Image</label>
      <div class="required">&nbsp;&nbsp;</div>
      <div class="section-event-image">
        <div id="preview-container">
          <div id="preview-header-title">Preview</div>
          <div id="preview-body-image">
            <img v-show="uploadedImageFileName  && !modifyImage" :src="baseURL + eventImagesPath + uploadedImageFileName">
            <preview
              v-show="!uploadedImageFileName || modifyImage"
              class="preview-result-example__preview"
              :image="result.image"
              :coordinates="result.coordinates"
              :width="300"
              :height="300"
            />
          </div><br/>
          <div id="preview-footer-menu">
            <button v-show="!modifyImage" @click="modifyImage = !modifyImage">Modify</button>
            <button v-show="modifyImage" @click="saveImageToFileSystem()">Save</button>
            <button v-show="modifyImage" @click="modifyImage = !modifyImage">Cancel</button>
            <button v-show="!modifyImage" @click="deleteImageFromFileSystem(true, uploadedImageFileName || imageFilename)">Delete</button>
          </div>
        </div>
        <div v-if="modifyImage">
          Tool START
          <cropper
            ref="cropper"
            class="preview-result-example__cropper"
            :src="baseURL + eventImagesPath + (uploadedImageFileName || imageFilename)"
            :stencil-component="$options.components.CircleStencil"
            :default-size="{ width: 300, height: 300 }"
            :canvas="{ height: 300, width: 300 }"
            @change="onChange"
            :debounce="false"
          />
          Tool END
        </div>
      </div>
    </div>

      <h2 style="text-align: left;">Registration Information</h2>
      <div id="externalRegistrationURL" class="form-container-section">
        <span class="form-container-label"> External Registartion URL : </span>
        <span class="form-container-input">
          <InputText name="externalRegistrationURL" v-model="externalRegistrationURL" size="75" />
        </span>
      </div>
      <div id="regDates" class="form-container-section">
        <span class="form-container-label"> Opens * : </span>
        <span class="form-container-input">
          <Calendar v-model="regOpen" 
            :showIcon="true"
            :class="{ 'p-invalid': errors.regOpen }" 
          />
        </span>
        <span class="form-container-label"> Closes * : </span>
        <span class="form-container-input">
          <Calendar v-model="regClose" 
            :showIcon="true"
            :class="{ 'p-invalid': errors.regClose }" 
          />
        </span>
        <span class="form-container-label"> Seats * : </span>
        <span class="form-container-input">
          <InputNumber name="seats" v-model="seats" :class="{ 'p-invalid': errors.seats }" />
        </span>
      </div>
      <div id="costProvince" class="form-container-section">
        <!--
        <span class="form-container-label"> Cost : </span>
        <span class="form-container-input">
          <span class="p-inputgroup-addon">$</span>
          <InputNumber mode="currency" currency="CAN" locale="en-CA" v-model="cost" />
        </span>
        -->
        <span class="form-container-label"> Province * : </span>
        <span class="form-container-input">
          <Dropdown :options="provinces" optionLabel="name" optionValue="code" placeholder="Select a province" v-model="provinceId" :class="{ 'p-invalid': errors.provinceId }" />
        </span>
      </div>

      <div id="submitButton">
        <Button 
          class="p-button-raised p-button-rounded p-button-lg"
          label="Submit"
          icon="pi pi-check"
          @click="submit"
        />
      </div>

    </div>
    </div>
    <cmsFooter />
  </div>
</template>

<script>
/* eslint-disable vue/no-unused-components */
// CORE
import { ref } from 'vue'
import { useRouter } from 'vue-router'
import { useField, useForm } from 'vee-validate'
import { object, string, number, boolean } from 'yup'

// 3rd Party Libraries and Helpers
import Button       from 'primevue/button'
import Editor       from 'primevue/editor'
import Calendar     from 'primevue/calendar'
import Checkbox     from 'primevue/checkbox'
import Dropdown     from 'primevue/dropdown'
import InputText    from 'primevue/inputtext'
import FileUpload   from 'primevue/fileupload'
import InputNumber  from 'primevue/inputnumber'
import RadioButton  from 'primevue/radiobutton'
import { useToast } from 'primevue/usetoast'
import UUID from 'uuid'

//Image Management
import { Cropper, CircleStencil, Preview } from "vue-advanced-cropper";
import "vue-advanced-cropper/dist/style.css";

// Components
import cmsHeader from '../../../components/cms/cmsHeader.vue'
import cmsFooter from '../../../components/cms/cmsFooter.vue'

// Model
import Trainer from '../../../schemas/Trainer.js'
import Event from '../../../schemas/Event.js'

// API
import CmsServices from '../../../services/CmsServices.js'
import EventServices from '../../../services/EventServices.js'
import TrainingServices from '../../../services/TrainerServices.js'

export default {
  name: 'cmsEventAdd',
  components: {
    Button,
    Editor,
    Dropdown,
    Calendar,
    Checkbox,
    cmsHeader,
    cmsFooter,
    InputText,
    FileUpload,
    InputNumber,
    RadioButton,
    Cropper,
    CircleStencil,
    Preview
  },
  setup() {
    const router = useRouter()
    const toast = useToast()
    const environment = process.env.VUE_APP_ENVIRONMENT
    const baseURL = process.env.VUE_APP_API_SERVER
    const trainerImagesPath = process.env.VUE_APP_TRAINER_IMAGES
    const eventImagesPath = process.env.VUE_APP_EVENT_IMAGES
    const event = ref(new Event())
    const trainer = ref(new Trainer())
    const trainers = ref([new Trainer()])
    const selectedTrainers = ref([])
    event.value.trainers = []
    // Image Management
    const result = ref ({ coordinates: null, image: null })
    const uploadedImageFileName = ref('')
    const originalImageFilename = ref('')
    const finalImage = ref(null)
    const modifyImage = ref(false)

    TrainingServices.getTrainingResources()
    .then((response) => {
      if(response.status != 200) {
        toast.add({severity: 'info', summary:'Information', detail: 'Something went wrong retrieving trainers!', life: 5000})
      } else {
        trainers.value = response.data
        // Use the event.trainers value to look up the full trainer and add to the selected trainers for display
        for (let i = 0; i < event.value.trainers.length; i++) {
          selectedTrainers.value.push(trainers.value.find(trainer => trainer.id === event.value.trainers[i]))
        }
      }
    })

    const provinces = [
      { name: 'Alberta', code: 1 },
      { name: 'British Columbia', code: 2 },
      { name: 'Manitoba', code: 3 },
      { name: 'New Brunswick', code: 4 },
      { name: 'Newfoundland and Labrador', code: 5 },
      { name: 'Northwest Territories', code: 6 },
      { name: 'Nova Scotia', code: 7 },
      { name: 'Nunavut', code: 8 },
      { name: 'Ontario', code: 9 },
      { name: 'Prince Edward Island', code: 10 },
      { name: 'Quebec', code: 11 },
      { name: 'Saskatchewan', code: 12 },
      { name: 'Yukon', code: 13 },
      { name: 'Other Country', code: 15 }
    ]

    const validationSchema = object({
      statusId: number().required('* required'),
      isSummerSession: boolean().required('* required'),
      title: string().required('* required').min(3),
      subTitle: string().required('* required').min(3),
      date: string().required('* required'),
      location: string().required('* required').min(3),
      description: string().required('* required'),
      program: string().required('* required'),
      regOpen: string().required('* required'),
      regClose: string().required('* required'),
      seats: number().required('* required').min(1),
      provinceId: number().required('* required')
    })

    const { handleSubmit, errors } = useForm({
      validationSchema,
      initialValues: {
        isConsecutive: false,
        accommodation: '',
        imageFilename: '',
        externalRegistrationURL: '',
        cost: 0
      }
    })

    const { value: isSummerSession } = useField('isSummerSession')
    const { value: statusId } = useField('statusId')
    const { value: isConsecutive } = useField('isConsecutive')
    const { value: date } = useField('date')
    const { value: title } = useField('title')
    const { value: subTitle } = useField('subTitle')
    const { value: location } = useField('location')
    const { value: description } = useField('description')
    const { value: program } = useField('program')
    const { value: accommodation } = useField('accommodation')
    let { value: imageFilename } = useField('imageFilename')
    const { value: externalRegistrationURL } = useField('externalRegistrationURL')
    const { value: regOpen } = useField('regOpen')
    const { value: regClose } = useField('regClose')
    const { value: seats } = useField('seats')
    const { value: cost } = useField('cost')
    const { value: provinceId} = useField('provinceId')

    const submit = handleSubmit(values => {
      const event = values
      saveEvent(event)
    })

    const updateTrainer = (newTrainer) => { 
      trainer.value = newTrainer
    }

    const uploadComplete = (data) => {
      // We use files as an array as we expect one day to receive more than one file
      // As of today [21-Jan-2021] we expect only a single file upload
      const result = JSON.parse(data.xhr.response)
      originalImageFilename.value = data.files[0].name // IE: test-05.png
      uploadedImageFileName.value = result.filename[0] // IE: CCC2A2E1-15BD-49F9-804D-397480A670EA_test-05.png
      // toast.add({severity:'success', summary: 'Success', detail:result.msg, life: 5000})
    }

    const uploadError = () => {
      toast.add({severity:'error', summary: 'Upload Error', detail:'Failed to upload the image!', life: 5000})
    }

    const saveEvent = (newEvent) => {
      event.value = newEvent
      if(!event.value.imageFilename) {
        event.value.imageFilename = uploadedImageFileName.value
      }
      event.value.trainers = selectedTrainers.value.map(trainer => trainer.id)
      newEvent.guid = UUID()
      newEvent.date = newEvent.date.toISOString().slice(0,-5)+"Z"
      //newEvent.endDate = newEvent.endDate.toISOString().slice(0,-5)+"Z"
      if(typeof newEvent.regOpen != 'undefined') { newEvent.regOpen = newEvent.regOpen.toISOString().slice(0,-5)+"Z" }
      if(typeof newEvent.regClose != 'undefined') { newEvent.regClose = newEvent.regClose.toISOString().slice(0,-5)+"Z" }

      // Remove prefix from url
      newEvent.externalRegistrationURL = newEvent.externalRegistrationURL.replace(/(^\w+:|^)\/\//, '');

      EventServices.addEvent(newEvent)
      .then((response) => {
        if(response.status != 200) {
          toast.add({severity:'error', summary: 'Error', detail:"An issue occurred, please try again.", life: 5000})
        } else {
          toast.add({severity:'success', summary: 'Saved Event', detail:`${response.data.title} has been saved!`, life: 5000})
          event.value = new Event()
          trainer.value = new Trainer()
          selectedTrainers.value = []
          router.push({ name:"cmsEventList" })
        }
      })
      .catch(() => {
        toast.add({severity:'error', summary: 'Error', detail:"An issue occurred, please try again.", life: 5000})
      })
    }

    // Used when the image is modified
    const saveImageToFileSystem = () => {
      EventServices.saveImageToFileSystem(originalImageFilename.value, finalImage.value)
      .then((response) => {
        if(response.status != 200) {
          toast.add({severity: 'info', summary: 'Information', detail: response.data, life: 5000})
        } else {
          toast.add({severity: 'success', summary: 'Success', detail: "Image Saved!", life: 5000})
          if(uploadedImageFileName.value) {
            deleteImageFromFileSystem(false, uploadedImageFileName.value)
            uploadedImageFileName.value = ''
          } if(imageFilename.value) {
            deleteImageFromFileSystem(false, imageFilename.value)
            imageFilename.value = ''
          }
          imageFilename.value = response.data.filename[0]
          modifyImage.value = false
        }
      })
    }

    const deleteImageFromFileSystem = (uiRequest, fileName) => {
      CmsServices.deleteSingleImageFromFileSystem(fileName)
      .then((response) => {
        if(response.status != 200) {
          toast.add({severity: 'info', summary: 'Information', detail: response.data, life: 5000})
        } else {
          if(uiRequest && uploadedImageFileName.value){
            uploadedImageFileName.value = ''
            toast.add({severity: 'success', summary: 'Success', detail: "Image deleted!", life: 5000})
          } if(uiRequest && imageFilename.value) {
            imageFilename.value = ''
            toast.add({severity: 'success', summary: 'Success', detail: "Image deleted!", life: 5000})
          }
        }
      })
      .catch((error) => {
        toast.add({severity: 'error', summary: 'Delete Image Error', detail: error.message, life: 5000})
      })
    }

    const addTrainer = () => {
      if(selectedTrainers.value.indexOf(trainer.value) < 0) { 
        selectedTrainers.value.push(trainer.value)
      } 
    }

    const removeTrainer = (index) => {
      selectedTrainers.value.splice(index, 1)
    }

    const onChange = ({ coordinates, image, canvas }) => {
      result.value = {
        coordinates, image
      }
      finalImage.value = canvas.toDataURL('image/png').split(';base64,')[1]
    }

    return {
      toast,
      baseURL,
      eventImagesPath,
      environment,
      trainerImagesPath,
      event,
      trainer,
      trainers,
      selectedTrainers,
      provinces,
      updateTrainer,
      uploadComplete,
      uploadError,
      saveEvent,
      saveImageToFileSystem,
      deleteImageFromFileSystem,
      addTrainer,
      removeTrainer,
      statusId,
      isSummerSession,
      date,
      isConsecutive,
      title,
      subTitle,
      location,
      description,
      program,
      accommodation,
      imageFilename,
      externalRegistrationURL,
      regOpen,
      regClose,
      seats,
      cost,
      provinceId,
      errors,
      submit,
      onChange,
      result,
      uploadedImageFileName,
      modifyImage

    }
  }
}
</script>

<style lang="scss">
.container {
  display: grid;
  grid-template-columns: 100%;
  justify-content: center;
}
.title {
  font-size: 22px;
  font-weight: 600;
  text-align: left;
  margin-bottom: 15px;
}
.content {
  margin-top: 10%;
  text-align: left;
}
.action-bar {
  display: grid;
  justify-content: right;
  margin-top: 5%;
}
.form-container {
  margin-top: 1%;
  margin-bottom: 15%;
}
.form-container-section {
  display: flex;
  padding-top: 5px;
  padding-bottom: 5px;
}
.form-container-label {
  display: flex;
  font-weight: 800;
  justify-content: flex-start;
  width: 20%;
}
.form-container-input {
  display: flex;
  width: 80%;
}
label {
  padding-left: 10px;
  padding-right: 10px;
}
.section-event-image {
  display: grid;
  grid: auto-flow / repeat(6,1fr);
  gap: 1rem;
  padding-left: 1.5rem;
}
.event-image {
  height: 150px;
  width: 150px;
  border-radius: 2rem;
  border: 1px solid lightgray;
}
.event-image-menu {
  display: flex;
  flex-direction: row nowrap;
  justify-content: space-around;
  padding-left: 5px;
  padding-right: 5px;
}
.event-image-menu-trash {
  font-size: 2rem;
  color: red; 
  cursor: pointer;
  margin-top: 5px;
}
.profile-image {
  width: 25px;
  border-color: Green;
  border-style: solid;
  border-width: 1px;
  border-radius: 75px;
  background-color: green;
}
.selected-profile-image {
  width: 100px;
  border-color: Green;
  border-style: solid;
  border-width: 1px;
  border-radius: 75px;
  background-color: green;
}
.message {
  color: red;
  font-weight: 550;
  font-size: 1.25em;
}
.error-message {
  display: flex;
  color: red;
  font-variant: small-caps;
  font-size: 1em;
  text-align: left;
  justify-items: left;
  margin-left: 21%;
}

.preview-result-example {
	display: flex;
	&__cropper {
		width: 300px;
	}
	&__previews {
		margin-left: 32px;
	}
	&__preview {
		border-radius: 50%;
		overflow: hidden;
		margin-top: 24px;
		margin-bottom: 24px;
		width: 100px;
		height: 100px;
		&--small {
			width: 60px;
			height: 60px;
		}
	}
	&__preview-image {
		width: 100%;
	}
	&__button {
		position: absolute;
		left: 16px;
		bottom: 0;
	}
}
</style>
