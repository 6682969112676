export default class Event {
  constructor(
    statusId,
    isSummerSession,
    title,
    subTitle,
    date, 
    location,
    isConsecutive,
    description,
    program,
    accommodation,
    trainers,
    imageFilename,
    regOpen,
    regClose,
    seats,
    cost,
    provinceId,
    externalRegistrationURL
  ) {
    this.statusId = statusId
    this.isSummerSession = isSummerSession
    this.title = title
    this.subTitle = subTitle
    this.date = date
    this.location = location
    this.isConsecutive = isConsecutive
    this.description = description
    this.program = program
    this.accommodation = accommodation
    this.trainers = trainers
    this.imageFilename = imageFilename
    this.regOpen = regOpen
    this.regClose = regClose
    this.seats = seats
    this.cost = cost
    this.provinceId = provinceId
    this.externalRegistrationURL = externalRegistrationURL
  }
}